import { LoginError } from "@m/login";
import { useAuth0Login } from "@m/login/hooks";
import { SpinnerScreen } from "@m/ui";

export const AtlasLogin = () => {
  const { error, redirectToAuth0Login } = useAuth0Login();

  if (error) {
    return <LoginError error={error} onRetry={redirectToAuth0Login} />;
  }

  return <SpinnerScreen />;
};
