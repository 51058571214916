import { useParams } from "react-router-dom";

import { Spinner } from "@m/ui";

import { useCustomerSubscriptions } from "../api";

import { CustomerSubscriptionsCard } from "./CustomerSubscriptionsCard";
import { CustomerSubscriptionTableEmpty } from "./CustomerSubscriptionsTableEmpty";

export const CustomerSubscriptionsTable = () => {
  const { customerId: id = "" } = useParams<{ customerId: string }>();
  const {
    data: { subscriptions = [] },
    loading,
  } = useCustomerSubscriptions(id);

  if (loading) return <Spinner />;
  if (subscriptions.length === 0) return <CustomerSubscriptionTableEmpty />;

  return (
    <div className="flex w-full flex-wrap place-content-baseline gap-2">
      {subscriptions.map((subscription) => {
        if (!subscription) return;

        return (
          <CustomerSubscriptionsCard
            subscription={subscription}
            key={subscription?.id}
          />
        );
      })}
    </div>
  );
};
