import {
  MATCH_ANY_PARAMETERS,
  WildcardMockedResponse,
  loadingState,
  successState,
} from "@m/api/testing";

import { EXAMPLE_CATALOG_ITEM, EXAMPLE_DIMENSIONS } from "../examples";
import { EXAMPLE_SUBSCRIPTION } from "../examples/subscriptionDetails.example";

import { GET_CUSTOMER_SUBSCRIPTION_DETAILS } from "./useCustomerSubscriptionDetails";

const mock: WildcardMockedResponse = {
  request: {
    query: GET_CUSTOMER_SUBSCRIPTION_DETAILS,
    variables: MATCH_ANY_PARAMETERS,
  },
  result: {
    data: {
      marketplaceRegistrations: {
        edges: [
          {
            node: {
              ...EXAMPLE_SUBSCRIPTION,
              catalogItem: {
                ...EXAMPLE_CATALOG_ITEM,
                dimensions: {
                  edges: EXAMPLE_DIMENSIONS.map((dimension) => ({
                    node: dimension,
                  })),
                },
              },
            },
          },
        ],
      },
    },
  },
};

export const MOCK_GET_CUSTOMER_SUBSCRIPTION_DETAILS = successState(mock);
export const MOCK_GET_CUSTOMER_SUBSCRIPTION_DETAILS_LOADING =
  loadingState(mock);
