/**
 * Paths constant used to keep track of all of the path segments used throughout Atlas.
 *
 * Relative paths are prefixed with an underscore (_).
 */
export const PATHS = {
  // Root
  ROOT: "/customers",

  CUSTOMER_DETAILS: "/customers/:customerId/overview",
  CUSTOMER_SUBSCRIPTIONS: "/customers/:customerId/subscriptions",
  CUSTOMER_SUBSCRIPTION_DETAILS:
    "/customers/:customerId/subscriptions/:marketplaceProductCode",
};
