import { Tooltip } from "@m/ui";
import { formatFullDate } from "@m/utils";

import { formatMeteredChargeDate } from "../utils";

interface Props {
  chargeDate: string | null | undefined;
  reportedDate: string;
}

export const MeteredChargeDateTooltip = ({
  chargeDate,
  reportedDate,
}: Props) => {
  const tooltipText = chargeDate
    ? formatFullDate(chargeDate, "MMMM d, yyyy, h:mm a ZZZZ")
    : `This charge was sent to AWS on ${formatFullDate(
        reportedDate,
        "MMMM d, yyyy"
      )} at ${formatFullDate(
        reportedDate,
        "h:mm a ZZZZ"
      )} and is still awaiting confirmation.`;

  const displayValue = (
    <span className="text-subdued">
      {formatMeteredChargeDate({
        isoDate: chargeDate,
        formats: {
          current: "MMMM d",
          past: "MMMM d, yyyy",
        },
      })}
    </span>
  );

  return (
    <>
      <Tooltip content={tooltipText} side="bottom" className="z-50 max-w-xs">
        <div
          className="select-text whitespace-nowrap underline decoration-slate-400 decoration-dashed underline-offset-2"
          aria-label="Charge Date"
        >
          {chargeDate ? displayValue : <PendingAWSConfirmation />}
        </div>
      </Tooltip>
      {chargeDate && (
        <>
          <span className="mx-0.5 text-slate-400">-</span>
          <span className="ml-auto flex-col font-thin text-slate-400">
            {formatFullDate(chargeDate, "h a")}
          </span>
        </>
      )}
    </>
  );
};

const PendingAWSConfirmation = () => (
  <div className="flex items-center">
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className="h-1 w-1 animate-pulse"
    >
      <circle cx="10" cy="10" r="10" className="fill-gray-300" />
    </svg>
    <span className="ml-1 text-gray-400">Pending</span>
  </div>
);
