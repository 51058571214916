import { useQuery } from "@m/api";
import { gql } from "@m/api/v4";
import { MarketplaceRegistrationFilter } from "@m/api/v4/types";

export const GET_CUSTOMER_SUBSCRIPTIONS = gql(/* GraphQL */ `
  query CustomerSubscriptions($where: MarketplaceRegistrationFilter) {
    marketplaceRegistrations(where: $where) {
      edges {
        node {
          id
          lastSuccessfulMeteredUsageTimestamp
          marketplaceProductCode
          expirationTime
          registrationStatus
          catalogItem {
            id
            status
            displayName
          }
        }
      }
    }
  }
`);

export const useCustomerSubscriptions = (companyId: string) => {
  const where: MarketplaceRegistrationFilter = {
    companyId: companyId,
  };
  const { data, loading, error } = useQuery(GET_CUSTOMER_SUBSCRIPTIONS, {
    variables: {
      where,
    },
  });

  const edges = data?.marketplaceRegistrations?.edges || [];
  const subscriptions = edges.map((edge) => edge?.node) || [];
  return {
    data: { subscriptions },
    loading,
    error,
  };
};
