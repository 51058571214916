import {
  CatalogItemStatus,
  MarketplaceMeteringMethod,
  MarketplaceRegistrationStatus,
} from "@m/api/v4/types";
import { Badge, TBadgeStatus } from "@m/ui";
import { fromSnakeCaseToProperCase } from "@m/utils";

interface Props {
  status:
    | MarketplaceRegistrationStatus
    | CatalogItemStatus
    | MarketplaceMeteringMethod
    | null;
  [propName: string]: any;
}

export const SUBSCRIPTION_INFO_BADGE_MAP = new Map<
  Props["status"],
  TBadgeStatus
>([
  [CatalogItemStatus.Active, "active"],
  [CatalogItemStatus.OneOff, "active"],
  [CatalogItemStatus.Preview, "active"],
  [CatalogItemStatus.Inactive, "default"],
  [CatalogItemStatus.Deprecated, "warning"],
  [CatalogItemStatus.NoSale, "negative"],
  [MarketplaceRegistrationStatus.Active, "active"],
  [MarketplaceRegistrationStatus.Inactive, "default"],
  [MarketplaceRegistrationStatus.Processing, "warning"],
  [MarketplaceRegistrationStatus.UnsubscribePending, "warning"],
  [MarketplaceRegistrationStatus.Unsubscribed, "negative"],
  [MarketplaceMeteringMethod.Manual, "default"],
  [MarketplaceMeteringMethod.Automated, "default"],
  [MarketplaceMeteringMethod.Contract, "default"],
]);

export const SubscriptionInfoBadge = ({ status, ...props }: Props) => {
  if (!status) return null;
  const statusFormatted = fromSnakeCaseToProperCase(status);
  const badgeStatus = SUBSCRIPTION_INFO_BADGE_MAP.get(status);

  return (
    <div className="flex-shrink">
      <Badge
        label={statusFormatted}
        status={badgeStatus || "default"}
        size="small"
        {...props}
      />
    </div>
  );
};
