import {
  ArrayParam,
  DateParam,
  StringParam,
  createEnumParam,
  withDefault,
} from "use-query-params";

import { AggregateBillingPeriod } from "@m/api/v4/types";
import { toProperCase } from "@m/utils";

export const DEFAULT_SORT_METERED_CHARGES = "SUCCESSFULLY_SENT_TIMESTAMP_DESC";

export const PERIOD_TABS = [
  AggregateBillingPeriod.Hourly,
  AggregateBillingPeriod.Daily,
  AggregateBillingPeriod.Monthly,
].map(toProperCase);

export const METERED_CHARGES_QUERY_PARAMS = {
  dimensions: ArrayParam,
  chargeDateStart: DateParam,
  chargeDateEnd: DateParam,
  reportedDateStart: DateParam,
  reportedDateEnd: DateParam,
  period: withDefault(
    createEnumParam<AggregateBillingPeriod>(
      Object.values(AggregateBillingPeriod)
    ),
    AggregateBillingPeriod.Hourly
  ),
  sort: withDefault(StringParam, DEFAULT_SORT_METERED_CHARGES),
} as const;
