import { generateMockId } from "@m/api/testing";
import { dt } from "@m/utils";

import { MeteredChargeType } from "../api";

const templateDate = dt.fromISO("2024-10-10T01:00:00Z");
const generateExampleMeteredCharge = ({
  id = generateMockId(),
  companyId = "789",
  marketplaceProductCode = generateMockId(),
  dimensionId = generateMockId(),
  atlasName = "10 Additional Monthly Pool Hours",
  apiName = "10monthlyPoolHours",
  dimensionCode = generateMockId(),
  quantity = 20,
  billingPeriodDate = templateDate,
  chargeDate = templateDate,
  reportedDate = templateDate.minus({ days: 2 }),
  accountId = "123",
  accountName = "John Smith",
} = {}) => {
  return {
    id,
    company: {
      id: companyId,
    },
    marketplaceProductCode,
    dimension: {
      id: dimensionId,
      atlasName,
      apiName,
    },
    dimensionCode,
    quantity,
    billingPeriod: {
      isoformat: billingPeriodDate.toISO(),
      year: billingPeriodDate.year,
    },
    chargeDate: chargeDate.toISO(),
    reportedDate: reportedDate.toISO(),
    account: {
      id: accountId,
      name: accountName,
    },
  } satisfies MeteredChargeType;
};

export const EXAMPLE_METERED_CHARGE = generateExampleMeteredCharge();
export const EXAMPLE_METERED_CHARGES = [
  EXAMPLE_METERED_CHARGE,
  generateExampleMeteredCharge({
    id: generateMockId(),
    companyId: "789",
    marketplaceProductCode: generateMockId(),
    dimensionId: generateMockId(),
    atlasName: "20 Adjusted Pool Hours",
    apiName: "20adjustedPoolHours",
    dimensionCode: generateMockId(),
    quantity: 30,
    billingPeriodDate: templateDate.minus({ years: 1 }),
    chargeDate: templateDate.minus({ weeks: 1 }),
    reportedDate: templateDate.minus({ weeks: 1, days: 2 }),
    accountId: "234",
    accountName: "Luca Pasedena",
  }),
  generateExampleMeteredCharge({
    id: generateMockId(),
    companyId: "789",
    marketplaceProductCode: generateMockId(),
    dimensionId: generateMockId(),
    atlasName: "Adjustment Charges",
    apiName: "adjustmentCharges",
    dimensionCode: generateMockId(),
    quantity: 10,
    billingPeriodDate: templateDate.minus({ weeks: 1 }),
    chargeDate: templateDate.minus({ weeks: 1 }),
    reportedDate: templateDate.minus({ weeks: 1, days: 2 }),
    accountId: undefined,
    accountName: undefined,
  }),
] satisfies MeteredChargeType[];

export const EXAMPLE_METERED_CHARGES_HOURLY = Array.from(
  { length: 14 },
  (_, index) =>
    generateExampleMeteredCharge({
      id: generateMockId(),
      quantity: index === 4 ? 1 : 0,
      chargeDate: index < 4 ? templateDate.plus({ hours: index }) : undefined,
      reportedDate: templateDate.plus({ hours: index }).minus({ days: 2 }),
      billingPeriodDate: templateDate.minus({ hours: index }),
    })
) satisfies MeteredChargeType[];

export const EXAMPLE_METERED_CHARGES_HOURLY_PAGINATED = Array.from(
  { length: 30 },
  (_, index) =>
    generateExampleMeteredCharge({
      id: generateMockId(),
      quantity: index === 4 ? 1 : 0,
      chargeDate: index < 4 ? templateDate.plus({ hours: index }) : undefined,
      reportedDate: templateDate.plus({ hours: index }).minus({ days: 2 }),
      billingPeriodDate: templateDate.plus({ hours: index }),
    })
) satisfies MeteredChargeType[];
