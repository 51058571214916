import { paginateNodes as paginateRelayNodes } from "graphql-mocks/relay";

export const paginateNodes = (
  nodes: Array<{ id: string }>,
  variables: {
    [key: string]: unknown;
    first?: number;
    last?: number;
    before?: string;
    after?: string;
  }
) => {
  const cursorForNode = (node: { id: string }) => node.id;

  const { pageInfo, edges } = paginateRelayNodes(
    nodes,
    variables,
    cursorForNode
  );

  const totalCount = nodes.length;

  return { pageInfo, edges, totalCount };
};
