import { useQuery } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Suspensed, useModalState } from "@m/ui";

import { PATHS } from "@atlas/constants";
import { GET_CUSTOMER_SUBSCRIPTION_DETAILS } from "@atlas/pages/CustomerSubscriptionDetailsPage/api";

import { useCustomerSubscriptionDetails } from "./api";
import {
  MeteredChargesTable,
  ProductInformationModal,
  SubscriptionDetailsTable,
} from "./components";

export const CustomerSubscriptionDetailsPage = () => {
  const navigate = useNavigate();

  const { customerId: id = "", marketplaceProductCode = "" } = useParams<{
    customerId: string;
    marketplaceProductCode: string;
  }>();

  const {
    data: { subscriptionDetails },
    loading: loadingSubscriptionDetails,
  } = useCustomerSubscriptionDetails(id, marketplaceProductCode);

  const { catalogItem } = subscriptionDetails ?? {};
  const productInfoModal = useModalState();

  if (!id) navigate(generatePath(PATHS.ROOT));
  if (!catalogItem && !loadingSubscriptionDetails) {
    toast.error("Could not find customer subscription.");
    navigate(
      generatePath(PATHS.CUSTOMER_SUBSCRIPTIONS, {
        customerId: id,
      })
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex pl-1 text-base font-semibold text-default">
        <Suspensed
          loading={loadingSubscriptionDetails}
          height="24px"
          width="280px"
        >
          <h1>{catalogItem?.displayName}</h1>
          <Button
            type="button"
            fill="none"
            className="m-0 ml-1 !p-0"
            iconClassName="h-2 w-2"
            leftIcon={InformationCircleIcon}
            onClick={productInfoModal.open}
          ></Button>
        </Suspensed>
      </div>
      <SubscriptionDetailsTable
        loading={loadingSubscriptionDetails}
        subscriptionDetails={subscriptionDetails}
      />
      <MeteredChargesTable subscriptionDetails={subscriptionDetails} />
      <ProductInformationModal
        catalogItem={subscriptionDetails?.catalogItem ?? null}
        isOpen={productInfoModal.isOpen}
        onClose={productInfoModal.close}
      />
    </div>
  );
};

CustomerSubscriptionDetailsPage.Crumb = function Crumb() {
  const { customerId, marketplaceProductCode } = useParams();
  const { data, loading } = useQuery(GET_CUSTOMER_SUBSCRIPTION_DETAILS, {
    variables: { where: { companyId: customerId, marketplaceProductCode } },
    fetchPolicy: "cache-first",
  });

  const displayName =
    data?.marketplaceRegistrations?.edges?.[0]?.node?.catalogItem
      ?.displayName ?? "Product";

  return (
    <Suspensed loading={loading} width="200px" height="20px">
      {displayName}
    </Suspensed>
  );
};
