import { Outlet } from "react-router-dom";

import { ApiProvider } from "@m/api";
import { useAuth } from "@m/login";

import { cacheConfig } from "@atlas/constants";

export const AuthenticatedAtlas = () => {
  const { logout } = useAuth();

  return (
    <ApiProvider
      cacheConfig={cacheConfig}
      context={{ clientName: "v4" }}
      errorOptions={{ logout }}
    >
      <Outlet />
    </ApiProvider>
  );
};
