import { useQuery } from "@m/api";
import { gql } from "@m/api/v4";

export const GET_CUSTOMER_FILTERS = gql(`
  query CustomerFilters {
    companyFilters {
      entitlements
    }
  }
`);

export const useCustomerFilters = () => {
  const { data, loading, error } = useQuery(GET_CUSTOMER_FILTERS);

  const filters = data?.companyFilters || {};
  const entitlements = filters.entitlements || [];

  return {
    data: { filters: { entitlements } },
    loading,
    error,
  };
};
