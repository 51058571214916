import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { gql } from "@m/api/v4";
import { DisableMarketplaceMeteringInput } from "@m/api/v4/types";

export const DISABLE_MARKETPLACE_METERING = gql(/* GraphQL */ `
  mutation disableMarketplaceMetering(
    $input: DisableMarketplaceMeteringInput!
  ) {
    disableMarketplaceMetering(input: $input) {
      ok
      message
    }
  }
`);

export const useDisableMarketplaceMetering = () => {
  const onError = () => {
    toast.error(
      "There was a problem disabling Marketplace Metering for this subscription."
    );
  };

  const [mutate, { loading }] = useMutation(DISABLE_MARKETPLACE_METERING, {
    onError,
  });

  const disableMarketplaceMetering = (
    data: DisableMarketplaceMeteringInput
  ) => {
    mutate({
      variables: { input: data },
      refetchQueries: ["GetCustomerSubscriptionDetails"],
    });
  };

  return [disableMarketplaceMetering, { loading }] as const;
};
