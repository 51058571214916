import {
  MATCH_ANY_PARAMETERS,
  WildcardMockedResponse,
  errorState,
  loadingState,
  successState,
} from "@m/api/testing";

import { EXAMPLE_AGGREGATE_METERED_CHARGES_DAILY } from "../examples";

import { GET_AGGREGATE_METERED_CHARGES_DAILY } from "./useAggregateMeteredChargesDaily";

const mock: WildcardMockedResponse = {
  request: {
    query: GET_AGGREGATE_METERED_CHARGES_DAILY,
    variables: MATCH_ANY_PARAMETERS,
  },
  result: {
    data: {
      aggregateMarketplaceMeteringTransactions: {
        edges: EXAMPLE_AGGREGATE_METERED_CHARGES_DAILY.map((node) => ({
          node: node,
        })),
      },
    },
  },
};

export const MOCK_GET_AGGREGATE_METERED_CHARGES_DAILY = successState(mock);
export const MOCK_GET_AGGREGATE_METERED_CHARGES_DAILY_LOADING =
  loadingState(mock);
export const MOCK_GET_AGGREGATE_METERED_CHARGES_DAILY_EMPTY = successState({
  ...mock,
  result: {
    data: {
      aggregateMarketplaceMeteringTransactions: { edges: [] },
    },
  },
});

export const MOCK_GET_AGGREGATE_METERED_CHARGES_DAILY_ERROR = errorState(mock);
