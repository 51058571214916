import { useQuery } from "@apollo/client";
import { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";

import { Badge, Suspensed } from "@m/ui";
import { toProperCase } from "@m/utils";

import { Breadcrumbs } from "@atlas/components";
import { GET_CUSTOMER_DETAILS } from "@atlas/pages/CustomerDetailsPage/api";

import { Sidebar } from "./CustomerDetailsSidebar";

export const CustomerDetailsLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="min-w-screen flex h-full min-h-screen w-full flex-col p-3">
      <Breadcrumbs />
      <div className="mt-2 flex min-w-[450px] grow">
        <Sidebar />
        <div className="flex grow flex-col overflow-auto">{children}</div>
      </div>
    </div>
  );
};

CustomerDetailsLayout.Crumb = function Crumb() {
  const { customerId } = useParams();
  const { data, loading } = useQuery(GET_CUSTOMER_DETAILS, {
    variables: { id: customerId ?? "" },
    fetchPolicy: "cache-first",
  });

  const companyName = data?.company?.name ?? "Company";
  const badgeStatus: "active" | "negative" =
    data?.company?.status === "Active" ? "active" : "negative";

  return (
    <Suspensed loading={loading} width="200px" height="20px">
      <span className="flex items-center gap-1">
        {companyName}
        <Badge
          label={toProperCase(data?.company?.status ?? "")}
          size="small"
          status={badgeStatus}
        />
      </span>
    </Suspensed>
  );
};
