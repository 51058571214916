import { DisableMarketplaceMeteringInput } from "@m/api/v4/types";
import { Banner, Button, Modal } from "@m/ui";

import { useDisableMarketplaceMetering } from "../api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  customerIdentifier: string;
}
export const StopMeteringModal = ({
  isOpen,
  onClose,
  customerIdentifier,
}: Props) => {
  const [disableMarketplaceMetering, { loading }] =
    useDisableMarketplaceMetering();

  const handleStopMetering = () => {
    const payload: DisableMarketplaceMeteringInput = {
      customerIdentifier,
    };
    disableMarketplaceMetering(payload);
    onClose();
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="md"
      header={<Modal.Title>Stop Metering</Modal.Title>}
    >
      <Banner
        label="Metering will need to be manually re-enabled"
        status="warning"
      />
      <Modal.Actions className="mt-3 inline-flex w-full justify-end">
        <Button
          type="submit"
          kind="primary"
          className="grow px-1"
          onClick={handleStopMetering}
          loading={loading}
          aria-label="Stop Metering Submit"
        >
          Stop Metering
        </Button>
        <Button
          type="button"
          fill="subdued"
          kind="secondary"
          onClick={onClose}
          loading={loading}
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
