import { Card } from "@m/ui";

export const CustomerSubscriptionTableEmpty = () => {
  return (
    <div className="flex max-w-full flex-row flex-wrap place-content-baseline gap-2">
      <Card className="flex min-h-[140px] min-w-[340px] flex-col border-2 border-dashed opacity-80">
        <div className="flex flex-1 items-center justify-center border-gray-300 text-subdued">
          <div className="font-base text-sm">No Subscriptions Found</div>
        </div>
      </Card>
      <Card className="flex min-h-[140px] min-w-[340px] flex-col border-2 border-dashed opacity-40">
        <div className="flex flex-1 items-center justify-center border-gray-300 text-subdued"></div>
      </Card>
      <Card className="flex min-h-[140px] min-w-[340px] flex-col border-2 border-dashed opacity-20">
        <div className="flex flex-1 items-center justify-center border-gray-300 text-subdued"></div>
      </Card>
      <Card className="flex min-h-[140px] min-w-[340px] flex-col border-2 border-dashed opacity-10">
        <div className="flex flex-1 items-center justify-center border-gray-300 text-subdued"></div>
      </Card>
    </div>
  );
};
