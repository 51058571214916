import clsx from "clsx";
import { ComponentType, ReactNode } from "react";
import { NavLink } from "react-router-dom";

import { Badge } from "@m/ui";

interface CustomerDetailsSidebarLinkProps {
  ariaLabel?: string;
  className?: string;
  count?: number;
  icon?: ComponentType<any>;
  loading?: boolean;
  title: ReactNode;
  to: string;
}

export const CustomerDetailsSidebarLink = ({
  ariaLabel,
  className,
  count,
  icon: Icon,
  loading = false,
  title,
  to = "",
}: CustomerDetailsSidebarLinkProps) => {
  return (
    <NavLink
      aria-label={ariaLabel || `Go to ${title}`}
      className={({ isActive }) =>
        clsx(
          "flex items-center space-x-1 rounded-lg px-1 py-0.5 font-medium transition",
          className,
          {
            "bg-layout-active/[.07] text-action": isActive,
          }
        )
      }
      to={to}
    >
      <span className="inline-flex grow items-center gap-1">
        {Icon && <Icon className="h-3 w-3 stroke-2" />}
        {title}
      </span>
      {!loading && count !== undefined && (
        <Badge
          aria-label={`${title} Count`}
          label={count}
          size="small"
          status={"active"}
          strong={false}
        />
      )}
    </NavLink>
  );
};
