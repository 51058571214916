import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import {
  GetCustomerSubscriptionDetailsQuery,
  MarketplaceRegistrationFilter,
} from "@m/api/v4/types";

export const GET_CUSTOMER_SUBSCRIPTION_DETAILS = gql(/* GraphQL */ `
  query GetCustomerSubscriptionDetails($where: MarketplaceRegistrationFilter) {
    marketplaceRegistrations(where: $where) {
      edges {
        node {
          id
          awsAccountId
          privateOfferId
          payerAccountId
          meteringStatus
          registrationStatus
          marketplaceProductCode
          customerIdentifier
          signupToken
          subscribeTime
          signupTime
          onboardingCompleteTimestamp
          expirationTime
          unsubscribeTime
          catalogItem {
            id
            productCode
            awsProductId
            description
            displayName
            dimensions {
              edges {
                node {
                  id
                  apiName
                  atlasName
                  atlasDescription
                  meteringMethod
                  dimensionTypeId
                }
              }
            }
            category
            status
            sku
          }
        }
      }
    }
  }
`);

export const useCustomerSubscriptionDetails = (
  companyId: string,
  marketplaceProductCode: string
) => {
  const where: MarketplaceRegistrationFilter = {
    companyId,
    marketplaceProductCode,
  };

  const { data, ...result } = useQuery(GET_CUSTOMER_SUBSCRIPTION_DETAILS, {
    variables: { where },
    fetchPolicy: "cache-and-network",
  });

  const node = data?.marketplaceRegistrations?.edges?.[0]?.node ?? null;
  const catalogItem = node?.catalogItem
    ? {
        ...node.catalogItem,
        dimensions:
          node.catalogItem.dimensions?.edges
            ?.map((edge) => edge?.node)
            .filter((node): node is DimensionType => node !== null) ?? [],
      }
    : null;
  const subscriptionDetails = node
    ? {
        ...node,
        catalogItem,
      }
    : null;
  return {
    data: { subscriptionDetails },
    ...result,
  };
};

// Pure types
export type SubscriptionDetailsType = SubscriptionDetailsBase & {
  catalogItem: SubscriptionCatalogItemType | null;
};

export type SubscriptionCatalogItemType = CatalogItemBase & {
  dimensions: DimensionType[];
};

export type DimensionType = NonNullable<DimensionEdge["node"]>;

// Mapped types
type MarketplaceRegistrationsConnection = NonNullable<
  GetCustomerSubscriptionDetailsQuery["marketplaceRegistrations"]
>;
type MarketplaceRegistrationEdge = NonNullable<
  MarketplaceRegistrationsConnection["edges"][number]
>;
type MarketplaceRegistrationNode = NonNullable<
  MarketplaceRegistrationEdge["node"]
>;
type SubscriptionDetailsBase = Omit<MarketplaceRegistrationNode, "catalogItem">;
type CatalogItem = NonNullable<MarketplaceRegistrationNode["catalogItem"]>;

type DimensionsConnection = NonNullable<CatalogItem["dimensions"]>;
type DimensionEdge = NonNullable<DimensionsConnection["edges"][number]>;

type CatalogItemBase = Omit<CatalogItem, "dimensions">;
