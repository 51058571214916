import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { AllProviders } from "@atlas/providers/AllProviders";
import { routes } from "@atlas/routes";

export const AtlasApp = () => {
  const router = createBrowserRouter([
    {
      element: <AllProviders />,
      children: routes,
    },
  ]);

  return <RouterProvider router={router} />;
};
