import { parseSort } from "@m/ui";
import { toCamelCase } from "@m/utils/strings";

export { generateMockId } from "./utils";

interface Node {
  id: string;
  [key: string]: any;
}

export const sortNodes = (nodes: Node[], sort: string): Node[] => {
  const { key, direction } = parseSort(sort);
  const sortKey = toCamelCase(key);

  nodes.sort((a, b) => {
    if (direction === "ASC") return a[sortKey] > b[sortKey] ? 1 : -1;
    else return b[sortKey] > a[sortKey] ? 1 : -1;
  });
  return nodes;
};
