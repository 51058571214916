import { MarketplaceMeteringMethod } from "@m/api/public/types";
import { generateMockId } from "@m/api/testing";

import { DimensionType } from "../api";

export const EXAMPLE_DIMENSIONS = [
  {
    id: generateMockId(),
    atlasName: "40 Monthly Pool Hours",
    atlasDescription:
      "This is the minimum amount of hours a customer can purchase",
    meteringMethod: MarketplaceMeteringMethod.Contract,
    dimensionTypeId: "40_MONTHLY_HOURS",
    apiName: "40monthlyPoolHours",
  },
  {
    id: generateMockId(),
    atlasName: "20 Adjusted Pool Hours",
    atlasDescription:
      "This is a special adjustment amount of hours a customer can purchase",
    meteringMethod: MarketplaceMeteringMethod.Contract,
    dimensionTypeId: "20_ADJUSTED_HOURS",
    apiName: "20adjustedPoolHours",
  },
  {
    id: generateMockId(),
    atlasName: "Adjustment Charges",
    atlasDescription: "Automated adjustments to the monthly pool hours",
    meteringMethod: MarketplaceMeteringMethod.Automated,
    dimensionTypeId: "ADJUSTMENT_CHARGES",
    apiName: "adjustmentCharges",
  },
  {
    id: generateMockId(),
    atlasName: "10 Additional Monthly Pool Hours",
    atlasDescription: "An additional block of 10 Monthly Pool Hours",
    meteringMethod: MarketplaceMeteringMethod.Manual,
    dimensionTypeId: "10_ADDITIONAL_MONTHLY_HOURS",
    apiName: "10additionalMonthlyPoolHours",
  },
  {
    id: generateMockId(),
    atlasName: "Overage Hours",
    atlasDescription:
      "Hours beyond the monthly pool hours approved by the customer",
    meteringMethod: MarketplaceMeteringMethod.Automated,
    dimensionTypeId: "OVERAGE_HOURS",
    apiName: "overageHours",
  },
] satisfies DimensionType[];
