import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import {
  AggregateBillingPeriod,
  GetAggregateMeteredChargesDailyQuery,
} from "@m/api/v4/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

export const GET_AGGREGATE_METERED_CHARGES_DAILY = gql(/* GraphQL */ `
  query GetAggregateMeteredChargesDaily(
    $marketplaceRegistrationSignupTokens: [ID!]
    $billingPeriodType: AggregateBillingPeriod!
    $dimensionTypeIds: [String]
    $first: Int
    $last: Int
  ) {
    aggregateMarketplaceMeteringTransactions(
      marketplaceRegistrationSignupTokens: $marketplaceRegistrationSignupTokens
      billingPeriodType: $billingPeriodType
      dimensionTypeIds: $dimensionTypeIds
      first: $first
      last: $last
    ) {
      edges {
        node {
          id
          billingPeriodType
          dimensionTypeId
          meteredQuantity
          hour
          day
          month
          year
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`);

interface DailyMeteredChargesParams {
  marketplaceSignupToken: string;
  query?: MeteredChargesDailyQueryParams;
}

export const useAggregateMeteredChargesDaily = (
  params: DailyMeteredChargesParams,
  onCompleted: (data: GetAggregateMeteredChargesDailyQuery) => void
) => {
  const onError = () =>
    toast.error("There was an error loading monthly metered charges");

  const { data, fetchMore, ...result } = useQuery(
    GET_AGGREGATE_METERED_CHARGES_DAILY,
    {
      variables: {
        ...INITIAL_PAGINATION,
        billingPeriodType: AggregateBillingPeriod.Daily,
        marketplaceRegistrationSignupTokens: [params.marketplaceSignupToken],
        dimensionTypeIds: params.query?.dimensions,
      },
      onError,
      onCompleted,
    }
  );

  const {
    edges,
    totalCount = 0,
    pageInfo = {},
  } = data?.aggregateMarketplaceMeteringTransactions ?? {};

  const aggregatedCharges =
    edges
      ?.map((edge) => edge?.node)
      .filter((node): node is AggregateMeteredChargeDaily => node !== null) ??
    [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return { data: { aggregatedCharges }, pagination, ...result };
};

type MeteredChargesDailyQueryParams = {
  dimensions: (string | null)[] | null | undefined;
};

type AggregateMeteredChargeDailyEdges = NonNullable<
  GetAggregateMeteredChargesDailyQuery["aggregateMarketplaceMeteringTransactions"]
>["edges"];

export type AggregateMeteredChargeDaily = NonNullable<
  NonNullable<AggregateMeteredChargeDailyEdges[number]>["node"]
>;
