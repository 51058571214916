import {
  ArrowUpOnSquareIcon,
  EllipsisVerticalIcon,
  NoSymbolIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

import { Dropdown, DropdownGroup, DropdownItem } from "@m/ui";

interface Props {
  disabled: boolean;
  onStopMeteringClick: () => void;
  onAddMeteredChargeClick: () => void;
  onExportMeteredChargesClick: () => void;
  canAddMeteredCharge: boolean;
  canExportMeteredCharges: boolean;
  canStopMetering: boolean;
}
export const MeteredChargesTableActions = ({
  disabled,
  onStopMeteringClick,
  onAddMeteredChargeClick,
  onExportMeteredChargesClick,
  canAddMeteredCharge,
  canExportMeteredCharges,
  canStopMetering,
}: Props) => {
  return (
    <Dropdown
      className="w-fit p-1"
      direction="bottom-start"
      trigger={
        <EllipsisVerticalIcon
          aria-label="Open Subscription Actions"
          className="mb-1 h-3 w-3"
        />
      }
    >
      <DropdownGroup aria-label="Subscription Actions">
        <DropdownItem
          className="p-1"
          onClick={onAddMeteredChargeClick}
          disabled={!canAddMeteredCharge || disabled}
          icon={PlusIcon}
        >
          Add Metered Charge
        </DropdownItem>
        <DropdownItem
          className="p-1"
          onClick={onExportMeteredChargesClick}
          disabled={!canExportMeteredCharges || disabled}
          icon={ArrowUpOnSquareIcon}
        >
          Export Metered Charges
        </DropdownItem>
      </DropdownGroup>
      <DropdownItem
        className="p-1"
        onClick={onStopMeteringClick}
        disabled={!canStopMetering || disabled}
        icon={NoSymbolIcon}
      >
        Stop Metering
      </DropdownItem>
    </Dropdown>
  );
};
