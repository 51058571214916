import { ReactNode } from "react";

import { UserDropdown } from "@atlas/components";

export const PageTitle = ({ title }: { title: ReactNode }) => {
  return (
    <div className="flex py-1 pr-1 text-default">
      <div className="grow text-xl font-bold">{title}</div>
      <UserDropdown />
    </div>
  );
};
