import { Card } from "@m/ui";

import { Field } from "./Field";

export const DetailsCard = ({
  title,
  fields,
  loading,
}: {
  title: string;
  fields: [string, string | number | undefined | null][];
  loading: boolean;
}) => {
  return (
    <Card
      title={<div className="text-sm font-semibold text-default">{title}</div>}
      className="w-full"
    >
      <div className="flex w-full flex-col space-y-2 overflow-auto p-2">
        {fields.map(([label, value]) => (
          <Field key={label} label={label} value={value} loading={loading} />
        ))}
      </div>
    </Card>
  );
};
