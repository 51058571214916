import * as Sentry from "@sentry/react";

const SENTRY_DSN =
  "https://901fde504071485ca1de1488eb097ff2@o93328.ingest.sentry.io/4505087707840512";

export const initializeSentry = () => {
  const release = process.env.REACT_APP_SENTRY_RELEASE;
  if (release) {
    let environment;
    switch (process.env.REACT_APP_SENTRY_ENV) {
      case "deploy/dev":
      case "main":
        environment = "development";
        break;
      case "deploy/prod":
        environment = "production";
        break;
      default:
        environment = "local";
    }

    Sentry.init({
      dsn: SENTRY_DSN,
      environment: environment,
      release: release,
      integrations: [Sentry.browserTracingIntegration()],
      debug: false, // turned this on to help debugging
      tracesSampleRate: 1.0,
    });

    console.log(`Sentry: enabled, env: ${environment}, release: ${release}`);
  } else {
    console.log("Sentry: disabled");
  }
};
