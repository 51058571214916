import {
  MATCH_ANY_PARAMETERS,
  WildcardMockedResponse,
  errorState,
  loadingState,
  successState,
} from "@m/api/testing";

import { EXAMPLE_AGGREGATE_METERED_CHARGES_MONTHLY } from "../examples";

import { GET_AGGREGATE_METERED_CHARGES_MONTHLY } from "./useAggregateMeteredChargesMonthly";

const mock: WildcardMockedResponse = {
  request: {
    query: GET_AGGREGATE_METERED_CHARGES_MONTHLY,
    variables: MATCH_ANY_PARAMETERS,
  },
  result: {
    data: {
      aggregateMarketplaceMeteringTransactions: {
        edges: EXAMPLE_AGGREGATE_METERED_CHARGES_MONTHLY.map((node) => ({
          node,
        })),
      },
    },
  },
};

export const MOCK_GET_AGGREGATE_METERED_CHARGES_MONTHLY = successState(mock);
export const MOCK_GET_AGGREGATE_METERED_CHARGES_MONTHLY_LOADING =
  loadingState(mock);
export const MOCK_GET_AGGREGATE_METERED_CHARGES_MONTHLY_EMPTY = successState({
  ...mock,
  result: {
    data: {
      aggregateMarketplaceMeteringTransactions: { edges: [] },
    },
  },
});

export const MOCK_GET_AGGREGATE_METERED_CHARGES_MONTHLY_ERROR =
  errorState(mock);
