/*
 * The default waitFor timeout in react-testing-library is 1000ms.
 * This constant adds a delay to mock graphql requests for simulating network
 * delays while staying within that timeout for simplicity in unit tests.
 *
 * Use together with jest.useFakeTimers() to reduce unit test run time.
 */
export const GQL_DELAY = 500;
export const MAX_TIMEOUT = Number.MAX_SAFE_INTEGER / 1000;
export const UNLIMITED = Number.POSITIVE_INFINITY;
