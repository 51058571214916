import { useQuery } from "@apollo/client";

import { gql } from "@m/api/v4";
import {
  AggregateBillingPeriod,
  GetMarketplaceMeteredChargesQuery,
  MarketplaceMeteringTransactionFilter,
  MarketplaceMeteringTransactionSortEnum,
} from "@m/api/v4/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";
import { dt } from "@m/utils";

export const GET_METERED_CHARGES = gql(/* GraphQL */ `
  query GetMarketplaceMeteredCharges(
    $where: MarketplaceMeteringTransactionFilter
    $sort: [MarketplaceMeteringTransactionSortEnum]
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    marketplaceMeteringTransactions(
      where: $where
      sort: $sort
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          id
          company {
            id
          }
          dimension {
            id
            atlasName
            apiName
          }
          quantity
          billingPeriod {
            isoformat
            year
          }
          dimensionCode
          marketplaceProductCode
          chargeDate
          reportedDate
          account {
            id
            name
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`);

interface HourlyMeteredChargesParams {
  marketplaceSignupToken: string | null;
  query: MeteredChargesQueryParams;
}
export const useMeteredCharges = (
  params: HourlyMeteredChargesParams,
  onCompleted: (data: GetMarketplaceMeteredChargesQuery) => void
) => {
  const onError = () =>
    toast.error("There was an error loading metered charges");
  const {
    chargeDateStart,
    chargeDateEnd,
    reportedDateStart,
    reportedDateEnd,
    dimensions,
    sort,
  } = params.query;

  const where: MarketplaceMeteringTransactionFilter = {
    marketplaceSignupToken: params.marketplaceSignupToken,
    chargeDateGte: chargeDateStart
      ? dt.fromJSDate(chargeDateStart).startOf("day").toUTC().toISO()
      : undefined,
    chargeDateLte: chargeDateEnd
      ? dt.fromJSDate(chargeDateEnd).endOf("day").toUTC().toISO()
      : undefined,
    reportedDateGte: reportedDateStart
      ? dt.fromJSDate(reportedDateStart).startOf("day").toUTC().toISO()
      : undefined,
    reportedDateLte: reportedDateEnd
      ? dt.fromJSDate(reportedDateEnd).endOf("day").toUTC().toISO()
      : undefined,
    dimensionCodeIn: dimensions
      ? dimensions.filter(
          (dimension): dimension is string => dimension !== null
        )
      : undefined,
  };
  const { data, fetchMore, ...result } = useQuery(GET_METERED_CHARGES, {
    variables: {
      ...INITIAL_PAGINATION,
      where,
      sort: sort as MarketplaceMeteringTransactionSortEnum,
    },
    onError,
    onCompleted,
  });

  const {
    edges,
    totalCount = 0,
    pageInfo = {},
  } = data?.marketplaceMeteringTransactions ?? {};

  const meteredCharges: MeteredChargeType[] =
    edges
      ?.map((edge) => {
        return edge?.node;
      })
      .filter(
        (charge): charge is NonNullable<typeof charge> => charge !== null
      ) ?? [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { meteredCharges },
    pagination,
    ...result,
  };
};

type MeteredChargesQueryParams = {
  dimensions: (string | null)[] | null | undefined;
  chargeDateStart: Date | null | undefined;
  chargeDateEnd: Date | null | undefined;
  reportedDateStart: Date | null | undefined;
  reportedDateEnd: Date | null | undefined;
  period: AggregateBillingPeriod;
  sort: string;
};

type MarketplaceMeteringTransactionsType = NonNullable<
  GetMarketplaceMeteredChargesQuery["marketplaceMeteringTransactions"]
>;
type MarketplaceMeteringTransactionEdgeType = NonNullable<
  MarketplaceMeteringTransactionsType["edges"][number]
>;
export type MeteredChargeType = NonNullable<
  MarketplaceMeteringTransactionEdgeType["node"]
>;
