import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom";

import { AtlasApp } from "./app";
import { initializeSentry } from "./utils";

if (process.env.NODE_ENV === "production") {
  initializeSentry();
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_ATLAS_APP_AUTH0_CLIENT_ID as string}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    >
      <AtlasApp />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
