import clsx from "clsx";

import { Company } from "@m/api/v4/types";
import { Suspensed } from "@m/ui";
import { formatFullDate } from "@m/utils";

import { DATA_MISSING_PLACEHOLDER } from "../constants";

interface Props {
  company?: Pick<
    Company,
    "status" | "name" | "mcn" | "timestampCreated"
  > | null;
  loading: boolean;
}

export const CustomerDetailsPageHeader = ({ loading, company }: Props) => {
  const isActive = company?.status === "Active";
  const name = company?.name || DATA_MISSING_PLACEHOLDER;
  const mcn = company?.mcn || DATA_MISSING_PLACEHOLDER;
  const dateAcquired = company?.timestampCreated
    ? formatFullDate(company.timestampCreated)
    : DATA_MISSING_PLACEHOLDER;

  return (
    <div id="Header" className="mb-2">
      <div className="flex items-center space-x-4">
        <div className="flex h-full w-full space-x-2 text-3xl font-bold text-default">
          <Suspensed loading={loading} width={"300px"} height={"40px"}>
            {name}
          </Suspensed>
          {!loading && (
            <div
              className={clsx(
                "ml-3 inline-flex items-center text-sm font-bold",
                {
                  "text-action": isActive,
                  "text-accent": !isActive,
                }
              )}
            >
              {isActive ? "ACTIVE" : "INACTIVE"}
            </div>
          )}
        </div>
      </div>

      <div className="mt-1 flex space-x-3">
        <div className="text-sm font-semibold text-subdued">
          <Suspensed loading={loading} width={"95px"} height={"20px"}>
            <div aria-label="MCN">{mcn}</div>
          </Suspensed>
        </div>
        <Suspensed loading={loading} width={"180px"} height={"20px"}>
          <div className="inline-flex justify-items-center space-x-1 text-sm font-semibold">
            <div className="text-subdued">Customer Since </div>
            <div className="text-default">
              <div aria-label="Date Acquired">{dateAcquired}</div>
            </div>
          </div>
        </Suspensed>
      </div>
    </div>
  );
};
