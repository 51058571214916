import { Navigate, useParams } from "react-router-dom";

import { useCustomerDetails } from "./api";
import {
  CustomerDetailsPageEntitlements,
  CustomerDetailsPageHeader,
  DetailsCard,
} from "./components";

export const CustomerDetailsPage = () => {
  const { customerId: id = "" } = useParams<{ customerId: string }>();

  const {
    data: {
      company,
      salesforce,
      entitlements: { bundles, products, addons, discounts },
    },
    loading,
  } = useCustomerDetails(id);

  if (!loading && !company) {
    return <Navigate to="/customers" />;
  }

  return (
    <div className="space-y-4">
      <CustomerDetailsPageHeader company={company} loading={loading} />

      <div id="Cards" className="flex gap-3">
        <DetailsCard
          title="Company Details"
          fields={[
            ["Website", salesforce?.website],
            ["Phone Number", salesforce?.phoneNumber],
            ["Size", salesforce?.companySize?.toString()],
            ["Industry", salesforce?.industry],
          ]}
          loading={loading}
        />
        <DetailsCard
          title="AWS Information"
          fields={[
            ["Representative", salesforce?.awsRep],
            ["Segment", salesforce?.awsSegment],
            ["Phase", salesforce?.awsPhases],
            ["District", salesforce?.awsDistrict],
          ]}
          loading={loading}
        />
        <DetailsCard
          title="Connectwise Information"
          fields={[
            ["ID", company?.connectwisePk],
            ["Identifier", company?.identifier],
          ]}
          loading={loading}
        />
      </div>

      <CustomerDetailsPageEntitlements
        title="Bundles"
        entitlements={bundles}
        loading={loading}
      />

      <CustomerDetailsPageEntitlements
        title="Products"
        entitlements={products}
        loading={loading}
      />

      <CustomerDetailsPageEntitlements
        title="Add-Ons / Discounts"
        entitlements={[...addons, ...discounts]}
        loading={loading}
      />
    </div>
  );
};
