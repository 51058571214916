import { useAuth } from "@m/login";
import { Avatar, Dropdown, DropdownItem } from "@m/ui";

export const UserDropdown = () => {
  const { user, logout } = useAuth();

  return (
    <Dropdown
      trigger={
        <Avatar account={user} className="cursor-pointer hover:opacity-80" />
      }
      direction="bottom-end"
      style={{ minWidth: 150 }}
    >
      <DropdownItem onClick={logout}>Log out</DropdownItem>
    </Dropdown>
  );
};
