import { useMemo } from "react";
import { useQueryParams } from "use-query-params";

import { GetAggregateMeteredChargesMonthlyQuery } from "@m/api/v4/types";
import { Table } from "@m/ui";

import { useAggregateMeteredChargesMonthly } from "../api";
import { METERED_CHARGES_QUERY_PARAMS } from "../constants";
import { formatMeteredChargeDate } from "../utils";

interface Props {
  marketplaceSignupToken: string;
  onSortChange: (...args: any[]) => any;
  onQueryCompleted: (data: GetAggregateMeteredChargesMonthlyQuery) => void;
}

export const MeteredChargesTableMonthly = ({
  onSortChange,
  marketplaceSignupToken,
  onQueryCompleted,
}: Props) => {
  const [query] = useQueryParams(METERED_CHARGES_QUERY_PARAMS);
  const {
    data: { aggregatedCharges },
    loading,
  } = useAggregateMeteredChargesMonthly(
    marketplaceSignupToken,
    {
      dimensions: query.dimensions ?? [],
    },
    onQueryCompleted
  );

  const rows = useMemo(() => {
    return aggregatedCharges.map((aggregatedCharge) => ({
      dimension: aggregatedCharge?.dimensionTypeId,
      chargeDate: formatMeteredChargeDate({
        year: aggregatedCharge?.year,
        month: aggregatedCharge?.month,
        formats: {
          current: "MMMM",
          past: "MMMM yyyy",
        },
      }),
      meteredUnits: aggregatedCharge?.meteredQuantity ?? "—",
      billingPeriod: formatMeteredChargeDate({
        year: aggregatedCharge?.year,
        month: aggregatedCharge?.month,
        formats: {
          current: "MMMM",
          past: "MMMM yyyy",
        },
      }),
    }));
  }, [aggregatedCharges]);

  return (
    <Table
      headerFontSize="small"
      headers={METERED_CHARGES_TABLE_MONTHLY_HEADERS}
      pageSize={30}
      loading={loading}
      defaultSort={null}
      onSortChange={onSortChange}
      showPagination={true}
      totalCount={aggregatedCharges.length}
      showHeader={true}
      rows={rows}
    />
  );
};

const METERED_CHARGES_TABLE_MONTHLY_HEADERS = [
  {
    label: "Billing Period",
    accessor: "billingPeriod",
    sort: "SUCCESSFULLY_SENT_TIMESTAMP",
    width: 170,
  },
  {
    label: "Charge Date",
    accessor: "chargeDate",
    width: 170,
    sort: "SUCCESSFULLY_SENT_TIMESTAMP",
  },
  {
    label: "Dimension",
    accessor: "dimension",
    sort: "DIMENSION_CODE",
    align: "left",
    width: 100,
  },
  {
    label: "Metered Units",
    accessor: "meteredUnits",
    sort: "QUANTITY",
    cellClasses: "text-right",
    align: "right",
    width: 170,
  },
  { label: " " },
];
