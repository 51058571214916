import clsx from "clsx";

import { Suspensed } from "@m/ui";

import { DATA_MISSING_PLACEHOLDER } from "../constants";

export const Field = ({
  label,
  value = DATA_MISSING_PLACEHOLDER,
  loading,
}: {
  label: string;
  value: string | number | undefined | null;
  loading: boolean;
}) => {
  const dataMissing = value === DATA_MISSING_PLACEHOLDER;
  return (
    <div className="ml-1 flex justify-between text-sm font-semibold text-default">
      {label}
      <div
        className={clsx("flex flex-wrap justify-end text-right font-normal", {
          "text-gray-400": dataMissing,
        })}
      >
        <Suspensed loading={loading} height="20px" width="96px">
          <div aria-label={label}>{value}</div>
        </Suspensed>
      </div>
    </div>
  );
};
