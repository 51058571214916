import { ReactNode, useMemo } from "react";

import { Badge, Modal } from "@m/ui";
import { fromSnakeCaseToProperCase } from "@m/utils";

import { SubscriptionInfoBadge } from "@atlas/components";

import { SubscriptionDetailsType } from "../api";

interface Props {
  catalogItem: SubscriptionDetailsType["catalogItem"];
  isOpen: boolean;
  onClose: () => void;
}

export const ProductInformationModal = ({
  catalogItem,
  isOpen,
  onClose,
}: Props) => {
  if (!catalogItem) return null;
  return (
    <Modal
      open={isOpen}
      size="xl"
      onClose={onClose}
      header={
        <Badge
          status="highlight"
          className="-ml-0.5"
          label="AWS Marketplace"
          strong={false}
          size="small"
        />
      }
    >
      <Modal.Title className="text-lg font-semibold">
        {catalogItem?.displayName ?? "No display name available"}
      </Modal.Title>
      <Modal.Description className="my-1 text-xs text-subdued">
        {catalogItem?.description ?? "No description available"}
      </Modal.Description>
      <hr className="my-3" />
      <div>
        <ProductDetailsSection catalogItem={catalogItem} />
        <DimensionsSection dimensions={catalogItem?.dimensions ?? []} />
      </div>
    </Modal>
  );
};

const ProductDetailsSection = ({
  catalogItem,
}: {
  catalogItem: SubscriptionDetailsType["catalogItem"];
}) => {
  if (!catalogItem) return null;
  return (
    <section
      aria-label="Product Information"
      className="grid grid-cols-[auto,1fr] gap-x-4 gap-y-3"
    >
      <DetailItem
        label="Status"
        value={<SubscriptionInfoBadge status={catalogItem?.status || null} />}
      ></DetailItem>
      <DetailItem label="Mission SKU" value={catalogItem?.sku} />
      <DetailItem label="AWS Product Code" value={catalogItem?.productCode} />
      <DetailItem label="AWS Product ID" value={catalogItem?.awsProductId} />
      <DetailItem
        label="AWS Product Type"
        value={fromSnakeCaseToProperCase(catalogItem?.category || "")}
      />
    </section>
  );
};

const DetailItem = ({ label, value }: { label: string; value?: ReactNode }) => {
  const id = `detail-${label.toLowerCase().replace(/\s+/g, "-")}`;
  return (
    <>
      <dt id={`${id}-label`} className="text-sm font-semibold">
        {label}
      </dt>
      <dd
        id={id}
        className="my-auto font-mono text-xs text-subdued"
        aria-labelledby={`${id}-label`}
      >
        {value ?? "—"}
      </dd>
    </>
  );
};

const DimensionsSection = ({
  dimensions = [],
}: {
  dimensions: NonNullable<SubscriptionDetailsType["catalogItem"]>["dimensions"];
}) => {
  // Sort dimensions by metering method, then by atlas name
  const sortedDimensions = useMemo(() => {
    return [...dimensions].sort((a, b) => {
      const methodA = a?.meteringMethod ?? "";
      const methodB = b?.meteringMethod ?? "";
      if (methodA !== methodB) return methodA.localeCompare(methodB);

      const nameA = a?.atlasName ?? "";
      const nameB = b?.atlasName ?? "";
      return nameA.localeCompare(nameB);
    });
  }, [dimensions]);
  if (dimensions.length === 0) return null;

  return (
    <section aria-label="Dimensions">
      <hr className="my-3" />
      <h3 className="mb-1 text-sm font-semibold">Dimensions</h3>
      <ol className="flex flex-col gap-y-1">
        {sortedDimensions.map((dimension) => (
          <li
            key={dimension?.id}
            className="h-7 rounded-md border border-zinc-100 bg-zinc-50 p-2"
          >
            <div className="mb-0.5 flex justify-between">
              <h3 className="text-xs font-semibold">{dimension?.atlasName}</h3>
              <SubscriptionInfoBadge
                status={dimension?.meteringMethod || null}
              />
            </div>
            <p className="text-xs font-normal text-subdued">
              {dimension?.atlasDescription}
            </p>
          </li>
        ))}
      </ol>
    </section>
  );
};
