import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { AuthContext } from "@m/login";
import { useAuth0Logout, useUserSession } from "@m/login/hooks";
import { UIProvider } from "@m/ui";

import { AtlasLogin, AuthenticatedAtlas } from "@atlas/app/components";

export const AllProviders = () => {
  const { user, updateUserSession } = useUserSession();
  const logout = useAuth0Logout();

  return (
    <UIProvider>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{ removeDefaultsFromUrl: true }}
      >
        <AuthContext.Provider value={{ user, updateUserSession, logout }}>
          {user ? <AuthenticatedAtlas /> : <AtlasLogin />}
        </AuthContext.Provider>
      </QueryParamProvider>
    </UIProvider>
  );
};
