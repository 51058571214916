import { Suspensed } from "@m/ui";

import { Entitlement } from "../types";

const getStatusColor = (status?: string | null) => {
  const statuses = {
    preview: "text-blue-500",
    active: "text-green-500",
    deprecated: "text-orange-500",
    no_sale: "text-red-500",
    one_off: "text-purple-500",
    inactive: "text-gray-500",
  };
  return (statuses as any)[String(status).toLowerCase()] || "text-black";
};

export const CustomerDetailsPageEntitlements = ({
  title,
  entitlements,
  loading,
}: {
  title: string;
  entitlements: (Entitlement | undefined | null)[];
  loading: boolean;
}) => {
  return (
    <div id={title} className="space-y-2">
      <div className="text-base font-semibold text-default">{title}</div>
      <div className="flex flex-col rounded-md border text-sm font-semibold text-default">
        <Suspensed loading={loading} height={"50px"}>
          {entitlements?.length ? (
            entitlements.map((entitlement) => (
              <div
                key={entitlement?.sku}
                className="grid grid-cols-3 border-b p-2 last:border-none"
              >
                <span className="content-start">{entitlement?.name}</span>
                <span className="content-between text-center font-normal text-gray-400">
                  {entitlement?.sku}
                </span>
                <span
                  className={`content-end text-right font-normal ${getStatusColor(
                    entitlement?.status
                  )}`}
                >
                  {entitlement?.status}
                </span>
              </div>
            ))
          ) : (
            <div className="border-b p-2 text-center font-normal text-gray-400 last:border-none">
              Customer has no {title}
            </div>
          )}
        </Suspensed>
      </div>
    </div>
  );
};
