import { MATCH_ANY_PARAMETERS, successState } from "@m/api/testing";

import { DISABLE_MARKETPLACE_METERING } from "./useDisableMarketplaceMetering";

export const MOCK_DISABLE_MARKETPLACE_METERING = successState({
  request: {
    query: DISABLE_MARKETPLACE_METERING,
    variables: MATCH_ANY_PARAMETERS,
  },
  result: {
    data: {
      disableMarketplaceMetering: {
        ok: true,
        message: "Metering disabled",
      },
    },
  },
});
