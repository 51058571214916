import { AggregateBillingPeriod } from "@m/api/v4/types";

import { MeteredChargesDailyDatePicker } from "./MeteredChargesDailyDatePicker";
import { MeteredChargesHourlyDatePicker } from "./MeteredChargesHourlyDatePIcker";
import { MeteredChargesMonthlyDatePicker } from "./MeteredChargesMonthlyDatePicker";

interface Props {
  cadence: AggregateBillingPeriod;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  label: string;
  onChange: (
    date: [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined
  ) => void;
  disabled?: boolean;
}
export const MeteredChargeDatePicker = ({
  cadence,
  label,
  startDate,
  endDate,
  onChange,
  disabled,
}: Props) => {
  return (
    <div className="flex flex-col">
      <label className="mb-0.5 text-sm font-medium">{label}</label>
      {cadence === AggregateBillingPeriod.Monthly && (
        <MeteredChargesMonthlyDatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          disabled={disabled}
        />
      )}
      {cadence === AggregateBillingPeriod.Hourly && (
        <MeteredChargesHourlyDatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          disabled={disabled}
        />
      )}
      {cadence === AggregateBillingPeriod.Daily && (
        <MeteredChargesDailyDatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};
