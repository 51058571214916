import { Navigate, Outlet } from "react-router-dom";

import { Logout } from "@m/login";

import { PATHS } from "@atlas/constants";
import { CustomerDetailsLayout } from "@atlas/layouts";
import { CustomerSubscriptionDetailsPage } from "@atlas/pages/CustomerSubscriptionDetailsPage";

import {
  CustomerDetailsPage,
  CustomerSubscriptionsPage,
  CustomersPage,
} from "../pages";

export const routes = [
  {
    index: true,
    element: <Navigate to="/customers" />,
  },
  {
    path: "customers",
    handle: {
      crumb: () => <span>Customers</span>,
      to: PATHS.ROOT,
    },
    children: [
      {
        element: <CustomersPage />,
        index: true,
      },
      {
        path: ":customerId",
        element: (
          <CustomerDetailsLayout>
            <Outlet />
          </CustomerDetailsLayout>
        ),
        handle: {
          crumb: () => <CustomerDetailsLayout.Crumb />,
          to: PATHS.CUSTOMER_DETAILS,
        },
        children: [
          {
            index: true,
            element: <Navigate to="overview" replace />,
          },
          {
            path: "overview",
            element: <CustomerDetailsPage />,
          },
          {
            path: "subscriptions",
            handle: {
              crumb: () => <span>Subscriptions</span>,
              to: PATHS.CUSTOMER_SUBSCRIPTIONS,
            },
            children: [
              {
                index: true,
                element: <CustomerSubscriptionsPage />,
              },
              {
                path: ":marketplaceProductCode",
                element: <CustomerSubscriptionDetailsPage />,
                handle: {
                  crumb: () => <CustomerSubscriptionDetailsPage.Crumb />,
                  to: PATHS.CUSTOMER_SUBSCRIPTION_DETAILS,
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "logout",
    element: <Logout />,
  },
];
