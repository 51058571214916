import clsx from "clsx";
import { FC, MouseEventHandler, ReactNode } from "react";

type ToggleFilterProps = {
  active: boolean;
  ariaLabel?: string;
  disabled?: boolean;
  icon?: ReactNode;
  label: string;
  onClick: MouseEventHandler;
};

export const ToggleFilter: FC<ToggleFilterProps> = ({
  active,
  ariaLabel,
  disabled,
  icon,
  label,
  onClick,
}) => {
  return (
    <button
      aria-label={ariaLabel}
      className={clsx(
        "select-none whitespace-nowrap rounded-full border px-2 py-1 text-sm font-semibold leading-none transition-colors duration-100 focus:outline-none",
        {
          "text-default": !active,
          "!border-layout-active bg-blue-50 !text-action": active,
          "hover:border-layout-active focus-visible:border-layout-active":
            !disabled,
          "cursor-not-allowed opacity-60": disabled && !active,
          "pl-2": !icon,
        }
      )}
      disabled={disabled}
      key={label}
      name={label}
      onClick={onClick}
      type="button"
    >
      {icon && (
        <span aria-label="Filter Icon" className="ml-1 mr-1">
          {icon}
        </span>
      )}
      <div className="flex h-2 items-center">{label}</div>
    </button>
  );
};
