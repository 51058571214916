import { CustomerSubscriptionsTable } from "./components";

export const CustomerSubscriptionsPage = () => {
  return (
    <div className="space-y-2 text-base font-semibold">
      <h2>Current Subscriptions</h2>
      <CustomerSubscriptionsTable />
    </div>
  );
};
