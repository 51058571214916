import { ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import {
  Link,
  UIMatch,
  generatePath,
  useMatches,
  useParams,
} from "react-router-dom";

type Crumb = (data: unknown) => React.ReactNode;

interface MatchWithHandle extends UIMatch {
  handle: {
    crumb?: Crumb;
    to?: string;
  };
}

export const Breadcrumbs = () => {
  const matches = useMatches() as MatchWithHandle[];
  const matchesWithCrumbs = matches.filter((match) => match.handle?.crumb);
  const crumbs = matchesWithCrumbs.map((match, index) => {
    return {
      crumb: match.handle?.crumb?.(match.data) ?? null,
      to: match.handle?.to,
      index,
    };
  });

  const params = useParams();
  return (
    <ol className="mb-1 ml-1 flex flex-row">
      {crumbs.map(({ crumb, to, index }) => {
        const isLast = index === crumbs.length - 1;
        return (
          <li key={index} className="inline-flex items-center">
            <span
              className={clsx("text-sm font-semibold text-subdued", {
                "!text-black": isLast,
              })}
            >
              {to && !isLast ? (
                <Link to={generatePath(to, params)}>{crumb}</Link>
              ) : (
                crumb
              )}
            </span>
            {!isLast ? (
              <ChevronRightIcon height="12px" className="px-1" />
            ) : null}
          </li>
        );
      })}
    </ol>
  );
};
