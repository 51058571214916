import { useMemo } from "react";
import { useQueryParams } from "use-query-params";

import { GetMarketplaceMeteredChargesQuery } from "@m/api/v4/types";
import { Table } from "@m/ui";

import { useMeteredCharges } from "../api";
import { METERED_CHARGES_QUERY_PARAMS } from "../constants";
import { formatMeteredChargeDate } from "../utils";

import { MeteredChargeDateTooltip } from "./MeteredChargeDateTooltip";

interface Props {
  marketplaceSignupToken: string;
  onSortChange: (...args: any[]) => any;
  onCompleted: (data: GetMarketplaceMeteredChargesQuery) => void;
}

export const MeteredChargesTableHourly = ({
  marketplaceSignupToken,
  onCompleted,
  onSortChange,
}: Props) => {
  const [query] = useQueryParams(METERED_CHARGES_QUERY_PARAMS);
  const {
    data: { meteredCharges },
    pagination,
    loading,
  } = useMeteredCharges(
    {
      marketplaceSignupToken,
      query,
    },
    onCompleted
  );

  const rows = useMemo(() => {
    return meteredCharges.map((meteredCharge) => {
      const dimensionDisplay =
        meteredCharge?.dimension?.atlasName ??
        meteredCharge?.dimension?.apiName;

      return {
        dimension: dimensionDisplay,
        meteredUnits: meteredCharge?.quantity ?? "—",
        chargeBy: meteredCharge?.account?.name ?? "—",
        reportedDate: formatMeteredChargeDate({
          isoDate: meteredCharge?.reportedDate,
        }),
        billingPeriod: formatMeteredChargeDate({
          isoDate: meteredCharge?.billingPeriod?.isoformat,
          formats: { current: "MMMM", past: "MMMM, yyyy" },
        }),
        chargeDate: (
          <MeteredChargeDateTooltip
            chargeDate={meteredCharge?.chargeDate}
            reportedDate={meteredCharge?.reportedDate}
          />
        ),
      };
    });
  }, [meteredCharges]);

  return (
    <Table
      headerFontSize="small"
      headers={METERED_CHARGES_TABLE_HOURLY_HEADERS}
      loading={loading}
      defaultSort={null}
      onSortChange={onSortChange}
      showHeader={true}
      rows={rows}
      {...pagination}
    />
  );
};

const METERED_CHARGES_TABLE_HOURLY_HEADERS = [
  {
    label: "Billing Period",
    accessor: "billingPeriod",
    width: 170,
    sort: "SUCCESSFULLY_SENT_TIMESTAMP",
  },
  {
    label: "Reported Date",
    accessor: "reportedDate",
    width: 170,
    sort: "SENT_TIMESTAMP",
  },
  {
    label: "Charge Date",
    accessor: "chargeDate",
    width: 180,
    sort: "SUCCESSFULLY_SENT_TIMESTAMP",
  },
  {
    label: "Dimension",
    accessor: "dimension",
    sort: "DIMENSION_CODE",
    align: "left",
    width: 170,
  },
  {
    label: "Metered Units",
    accessor: "meteredUnits",
    sort: "QUANTITY",
    cellClasses: "text-right",
    align: "right",
    width: 100,
  },
  { label: " ", width: 100 },
  {
    label: "Charge By",
    accessor: "chargeBy",
    sort: "MANUALLY_SENT_BY",
    width: 100,
  },
  { label: "  " },
];
