import { useMemo } from "react";
import { useQueryParams } from "use-query-params";

import { GetAggregateMeteredChargesDailyQuery } from "@m/api/v4/types";
import { Table } from "@m/ui";

import { useAggregateMeteredChargesDaily } from "../api";
import { METERED_CHARGES_QUERY_PARAMS } from "../constants";
import { formatMeteredChargeDate } from "../utils";

interface Props {
  marketplaceSignupToken: string;
  onSortChange: (...args: any[]) => any;
  onQueryCompleted: (data: GetAggregateMeteredChargesDailyQuery) => void;
}

export const MeteredChargesTableDaily = ({
  marketplaceSignupToken,
  onSortChange,
  onQueryCompleted,
}: Props) => {
  const [query] = useQueryParams(METERED_CHARGES_QUERY_PARAMS);

  const {
    data: { aggregatedCharges },
    pagination,
    loading,
  } = useAggregateMeteredChargesDaily(
    {
      marketplaceSignupToken,
      query,
    },
    onQueryCompleted
  );

  const rows = useMemo(() => {
    return aggregatedCharges.map((aggregatedCharge) => {
      const { month, day, year } = aggregatedCharge ?? {};
      return {
        dimension: aggregatedCharge?.dimensionTypeId,
        meteredUnits: aggregatedCharge?.meteredQuantity ?? "—",
        chargeDate: formatMeteredChargeDate({ year, month, day }),
        reportedDate: formatMeteredChargeDate({ year, month, day }),
        billingPeriod: formatMeteredChargeDate({
          year,
          month,
          formats: { current: "MMMM", past: "MMMM, yyyy" },
        }),
      };
    });
  }, [aggregatedCharges]);

  return (
    <Table
      headerFontSize="small"
      headers={METERED_CHARGES_TABLE_DAILY_HEADERS}
      loading={loading}
      defaultSort={null}
      onSortChange={onSortChange}
      showHeader={true}
      rows={rows}
      {...pagination}
    />
  );
};

const METERED_CHARGES_TABLE_DAILY_HEADERS = [
  { label: "Billing Period", accessor: "billingPeriod", width: 170 },
  { label: "Charge Date", accessor: "chargeDate", width: 170 },
  {
    label: "Dimension",
    accessor: "dimension",
    sort: "DIMENSION_CODE",
    align: "left",
    width: 100,
  },
  {
    label: "Metered Units",
    accessor: "meteredUnits",
    sort: "QUANTITY",
    cellClasses: "text-right",
    align: "right",
    width: 170,
  },
  { label: " " },
];
