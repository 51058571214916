import { CustomerDetailsSidebarLink } from "./CustomerDetailsSidebarLink";

export const Sidebar = () => {
  return (
    <nav data-testid="sidebar" className="mr-3 min-w-[160px]">
      <menu>
        <CustomerDetailsSidebarLink title="Overview" to="./overview" />
        <CustomerDetailsSidebarLink
          title="Subscriptions"
          to="./subscriptions"
        />
      </menu>
    </nav>
  );
};
