import { useQuery } from "@m/api";
import { gql } from "@m/api/v4";

export const GET_CUSTOMER_DETAILS = gql(`
  query GetCustomerDetails($id: ID!) {
    company(id: $id) {
      salesforceCustomerAccount {
        website
        phoneNumber
        companySize
        industry
        awsRep
        awsSegment
        awsPhases
        awsDistrict
      }
      id
      mcn
      name
      status
      connectwisePk
      databaseId
      identifier
      entitlements
      timestampCreated
      companyEntitlements {
        bundles {
          edges {
            node {
              sku
              name
              status
            }
          }
        }
        products {
          edges {
            node {
              sku
              name
              status
            }
          }
        }
        addons {
          edges {
            node {
              sku
              name
              status
            }
          }
        }
        discounts {
          edges {
            node {
              sku
              name
              status
            }
          }
        }
      }
    }
  }
`);

export const useCustomerDetails = (id: string) => {
  const { data, ...result } = useQuery(GET_CUSTOMER_DETAILS, {
    variables: { id },
  });

  const company = data?.company;
  const salesforce = company?.salesforceCustomerAccount;
  const entitlements = company?.companyEntitlements;

  const bundles = entitlements?.bundles?.edges?.map((edge) => edge?.node) || [];
  const products =
    entitlements?.products?.edges?.map((edge) => edge?.node) || [];
  const addons = entitlements?.addons?.edges?.map((edge) => edge?.node) || [];
  const discounts =
    entitlements?.discounts?.edges?.map((edge) => edge?.node) || [];

  return {
    data: {
      company,
      salesforce,
      entitlements: { bundles, products, addons, discounts },
    },
    ...result,
  };
};
