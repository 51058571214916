import {
  MATCH_ANY_PARAMETERS,
  WildcardMockedResponse,
  errorState,
  loadingState,
  paginateNodes,
  sortNodes,
  successState,
} from "@m/api/testing";

import {
  EXAMPLE_METERED_CHARGES_HOURLY,
  EXAMPLE_METERED_CHARGES_HOURLY_PAGINATED,
} from "../examples/meteredCharges.example";

import { GET_METERED_CHARGES } from "./useMeteredCharges";

const mock: WildcardMockedResponse = {
  request: {
    query: GET_METERED_CHARGES,
    variables: MATCH_ANY_PARAMETERS,
  },
  result: (variables) => {
    const sort = variables?.sort;
    const nodes = sortNodes(EXAMPLE_METERED_CHARGES_HOURLY, sort);

    return {
      data: {
        marketplaceMeteringTransactions: {
          edges: nodes.map((node: object) => ({ node })),
          pageInfo: {
            startCursor: "",
            endCursor: "",
          },
          totalCount: EXAMPLE_METERED_CHARGES_HOURLY.length,
        },
      },
    };
  },
};

export const MOCK_GET_MARKETPLACE_METERED_CHARGES = successState(mock);
export const MOCK_GET_MARKETPLACE_METERED_CHARGES_PAGINATED = successState({
  ...mock,
  result: (variables) => {
    const sort = variables?.sort;
    const nodes = sortNodes(EXAMPLE_METERED_CHARGES_HOURLY_PAGINATED, sort);

    return {
      data: {
        marketplaceMeteringTransactions: paginateNodes(nodes, { ...variables }),
      },
    };
  },
});
export const MOCK_GET_MARKETPLACE_METERED_CHARGES_EMPTY = successState({
  ...mock,
  result: {
    data: {
      marketplaceMeteringTransactions: {
        edges: [],
        pageInfo: {
          startCursor: "",
          endCursor: "",
        },
        totalCount: 0,
      },
    },
  },
});

export const MOCK_GET_MARKETPLACE_METERED_CHARGES_LOADING = loadingState(
  MOCK_GET_MARKETPLACE_METERED_CHARGES
);

export const MOCK_GET_MARKETPLACE_METERED_CHARGES_ERROR = errorState(mock);
