import { generateMockId } from "@m/api/testing";
import { AggregateBillingPeriod } from "@m/api/v4/types";
import { dt } from "@m/utils";

import {
  AggregateMeteredChargeDaily,
  AggregateMeteredChargeMonthly,
} from "../api";

const exampleAggregateMeteredCharge = ({
  dimensionTypeId,
  billingPeriod,
  quantity,
  date,
}: {
  dimensionTypeId: string;
  billingPeriod: AggregateBillingPeriod;
  quantity: number;
  date: dt;
}) => {
  return {
    id: generateMockId(),
    billingPeriodType: billingPeriod,
    dimensionTypeId: dimensionTypeId,
    meteredQuantity: quantity,
    hour: date.hour,
    day: date.day,
    month: date.month,
    year: date.year,
  };
};

const exampleAggregateMeteredChargeDaily = ({
  dimensionTypeId,
  quantity,
  date,
}: {
  dimensionTypeId: string;
  quantity: number;
  date: dt;
}) => {
  return exampleAggregateMeteredCharge({
    dimensionTypeId,
    billingPeriod: AggregateBillingPeriod.Daily,
    quantity,
    date,
  });
};

const exampleAggregateMeteredChargeMonthly = ({
  dimensionTypeId,
  quantity,
  date,
}: {
  dimensionTypeId: string;
  quantity: number;
  date: dt;
}) => {
  return exampleAggregateMeteredCharge({
    dimensionTypeId,
    billingPeriod: AggregateBillingPeriod.Monthly,
    quantity,
    date,
  });
};

const freezeDate = dt.fromISO("2024-11-04T10:00:00");
export const EXAMPLE_AGGREGATE_METERED_CHARGES_DAILY: AggregateMeteredChargeDaily[] =
  Array.from({
    length: 31,
  }).map((_, index) =>
    exampleAggregateMeteredChargeDaily({
      dimensionTypeId: "MonthlyUsageTier_1",
      quantity: Math.floor(Math.random() * 10 + 31),
      date: freezeDate.plus({ days: index }),
    })
  );

export const EXAMPLE_AGGREGATE_METERED_CHARGES_MONTHLY: AggregateMeteredChargeMonthly[] =
  Array.from({
    length: 15,
  }).map((_, index) =>
    exampleAggregateMeteredChargeMonthly({
      dimensionTypeId: "MonthlyUsageTier_1",
      quantity: Math.floor(Math.random() * 10 + 31),
      date: freezeDate.minus({ months: index }),
    })
  );
