import { useQuery } from "@m/api";
import { gql } from "@m/api/v4";
import { CompanySortEnum } from "@m/api/v4/types";
import { INITIAL_PAGINATION, usePaginationProps } from "@m/ui";

export const GET_CUSTOMER_LIST = gql(`
  query CustomerList(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $searchTerm: String
    $sort: [CompanySortEnum]
    $where: CompaniesFilter
  ) {
    companies(
      before: $before
      after: $after
      first: $first
      last: $last
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          name
          mcn
          id
          types
          status
          databaseId
          entitlements
          hasActiveMarketplaceRegistration
        }
      }
    }
  }
`);

interface CustomerListParams {
  marketplace: boolean;
  active: boolean;
  entitlements: (string | null)[] | never[];
  search: string;
  sort: string;
}

export const useCustomerList = (query: CustomerListParams) => {
  const { data, loading, error, fetchMore } = useQuery(GET_CUSTOMER_LIST, {
    variables: {
      ...INITIAL_PAGINATION,
      searchTerm: query.search,
      sort: query.sort as CompanySortEnum,
      where: {
        or: [
          { typesContains: ["Customer"] },
          { typesContains: ["Former Customer"] },
        ],
        ...(query.active ? { status: "Active" } : {}),
        ...(query.entitlements.length > 0
          ? { typesContains: query.entitlements }
          : {}),
        ...(query.marketplace
          ? { hasActiveMarketplaceRegistration: true }
          : {}),
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const { edges, pageInfo = {}, totalCount = 0 } = data?.companies || {};

  const customers = edges?.map((edge) => edge?.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return { data: { customers }, loading, error, pagination };
};
