import {
  ArrowLongLeftIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/20/solid";

import { Banner, Button } from "@m/ui";

import { type ManuallyMeteredDimensionType } from "../api";

import { type MeteredChargeInformation } from "./AddMeteredChargeModal";

interface Props {
  onModifyClick: () => void;
  onChargeToAWS: () => void;
  subscription: MeteredChargeInformation;
  dimension: ManuallyMeteredDimensionType | null;
  dimensionQuantity: number;
  createMeteredChargeLoading: boolean;
}

export const AddMeteredChargeReview = ({
  onModifyClick,
  onChargeToAWS,
  dimension,
  dimensionQuantity,
  subscription,
  createMeteredChargeLoading,
}: Props) => {
  return (
    <div className="flex flex-col space-y-2">
      <Banner
        label="This charge cannot be changed or undone after being sent to AWS Marketplace."
        status="warning"
      />
      <div className="flex flex-col gap-2 rounded-lg border border-gray-200 bg-zinc-50 p-2">
        <div className="text-sm font-semibold">Lapdog LLC</div>
        <div className="inline-flex">
          <div className="text-sm">Subscription</div>
          <div className="my-auto ml-auto text-xs text-subdued">
            {subscription.displayName}
          </div>
        </div>
        <div className="inline-flex">
          <div className="text-sm">{dimension?.atlasName}</div>
          <div className="my-auto ml-auto text-xs text-subdued">
            {dimensionQuantity}
          </div>
        </div>
      </div>
      <div className="inline-flex">
        <Button
          onClick={onModifyClick}
          type="button"
          fill="subdued"
          kind="secondary"
          className="mr-3 px-3"
        >
          <ArrowLongLeftIcon className="mr-1 h-2 w-4" /> Modify
        </Button>
        <Button
          type="submit"
          kind="primary"
          className="grow px-1"
          onClick={onChargeToAWS}
          disabled={createMeteredChargeLoading}
        >
          <CurrencyDollarIcon className="mr-1 h-2 w-4" /> Charge to AWS
          Marketplace
        </Button>
      </div>
    </div>
  );
};
