import ReactDatePicker from "react-datepicker";

interface Props {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  onChange: (
    date: [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined
  ) => void;
  disabled?: boolean;
  selectsRange?: boolean;
}

export const MeteredChargesHourlyDatePicker = ({
  startDate,
  endDate,
  onChange,
  disabled,
  selectsRange,
}: Props) => {
  return (
    <ReactDatePicker
      selected={startDate}
      onChange={onChange}
      disabled={disabled}
      clearButtonTitle="Clear"
      wrapperClassName="w-[210px]"
      className="h-4 w-[210px] rounded-md border border-gray-300 p-1"
      monthClassName={() => "p-1"}
      isClearable={true}
      selectsRange={selectsRange}
      startDate={startDate}
      endDate={endDate}
      dateFormat="M/d/yyyy"
    />
  );
};
